<template>
	<span>
		<i class="el-icon-question" @click="showModal" style="color: red;margin-left: 5px"></i>
		<el-dialog
				:visible.sync="dialogVisible"
				width="30%"
				:before-close="handleClose">
			<slot></slot>
		</el-dialog>
	</span>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。

	export default {
		data() {
			return {
				dialogVisible:false,
			}
		},
		props: {

		},
		methods: {
			showModal(){
				this.dialogVisible = true;
			},
			handleClose(done){
				this.dialogVisible = false;
				done()
			}
		},
		components: {

		},

		beforeMount() {

		},
		mounted() {

		},


	}
</script>

<style lang="less" scoped>

</style>
