<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>账户充值</span>
			</div>
			<div>
				<opera-tip>
					充值请
					<span class="primary">先转账，后提交</span>
					操作。请勿在转账备注中填写
					<span class="primary">任何关于刷单、刷流量、领商品之类</span>
					的备注，工作时间充值早上
					<span class="primary">09:00到凌晨00:00内</span>
					审核
				</opera-tip>
			</div>
			<el-tabs type="border-card" @tab-click="tabChange">
				<el-tab-pane :label="item.cardType == 2?'银行卡充值':'支付宝充值'" v-for="(item,index) in accontData " :key="index">
					<div class="rechargeBox">
						<div class="left">
							<el-form ref="ruleForm" size="medium" :model="form"  label-Position="right" label-width="140px">
								<el-form-item label="收款户名：" >
									{{item.accountName}}
								</el-form-item>
								<el-form-item label="收款账号：">
									{{item.cardNumber}}
								</el-form-item>
								<el-form-item
										label="转账金额："
										prop="amount"
										:rules="[
												{required:true,validator:price,trigger:'blur'}
										]">
									<el-input  v-model="form.amount" placeholder="请输入转账金额" style="width: 200px;" :maxlength="7"></el-input>
								</el-form-item>
								<el-form-item
										prop="recAccount"
										:label="item.cardType==2?'打款账户后四位：':'支付宝订单号：'"
										:rules="[
												{required:true,message:item.cardType==2?'请输入账户后四位':'请输入支付宝订单号',trigger:'blur'}

										]">
									<el-input  v-model="form.recAccount" :placeholder="item.cardType==2?'请输入账户后四位':'请输入支付宝订单号'"  :maxlength="40" style="width: 200px;"></el-input>
									<show-modal-tip v-if="item.cardType!=2">
										<img src="../../image/orderInfo.jpg" style="width: 540px" alt="">
									</show-modal-tip>
								</el-form-item>
								<el-form-item
										label="打款人姓名："
										:rules="[
													{required:true,message:'请输入打款人姓名',trigger:'blur'}
											]"
										prop="name">
									<el-input v-model="form.name" style="width: 200px;" placeholder="请输入打款人姓名" > </el-input>
								</el-form-item>
								<el-form-item
										prop="rechargeScree"
										:label="item.cardType==2?'充值凭证：':'订单截图：'"
										:rules="[
												{required:true,message:item.cardType==2?'请上传充值凭证':'请上传订单截图',trigger:'change'}
										]">
									<sb-upload @handleSuccess="handleSuccess" :type="3" :imageUrl="form.rechargeScree" :imgStyle="{width:'100px',height:'100px'}"></sb-upload>
								</el-form-item>
								<div style="text-align: center">
									<el-button type="primary" :loading="loading" @click="submit(item.bankCardId)">提交</el-button>
								</div>
							</el-form>
						</div>
						<div class="right" v-if="item.cardType != 2">
							<div class="red warning">注意：请不要使用店铺绑定的支付宝充值</div>
							<div>
								<el-image v-bind:src="item.receivingCode"></el-image>
							</div>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</el-card>
	</div>
</template>

<script>
	import OperaTip from '../../components/opera_tip/opera_tip.vue'
	import {ACCOUNTLIST,RECHARGE} from '../../api/base'
	import SbUpload from '../../components/upload/upload.vue'
	import ShowModalTip from '../../components/showModalTip/showModalTip.vue'
	import {price} from '../../tools/validate'

	export default {
		data() {
			return {

				accontData:[],
				price,
				loading:false,
				form:{
					payMethodId:undefined,
					name:undefined,
					recAccount:undefined,
					amount:undefined,
					rechargeScree:undefined,
				}
			}
		},
		methods: {
			tabChange(){
				this.$refs.ruleForm[0]?.resetFields();
				this.$refs.ruleForm[1]?.resetFields();
				this.reset()
			},
			reset(){
				for (var item in this.form){
					this.form[item] = undefined
				}
			},
			submit(bankCardId){
				this.form.payMethodId = bankCardId
				this.$refs.ruleForm[0].validate((valid)=>{
					if(valid){
						this.loading = true;
						this.$axios.post(RECHARGE,this.form).then(resp=>{
							if(resp.code == 200){
								this.loading = false;
								this.$mes({message: resp.msg, })
								this.reset()
							}
						})
						// this.$axios.post(RECHARGE,this.form).then(resp=>{
						// 	if(resp.code == 200){
						// 		this.loading = false;
						// 		this.$mes({message: resp.msg, })
						// 		this.reset()
						// 	}
						// })
					}
				})
			},
			getApayList() {
				this.$axios.get(ACCOUNTLIST).then(res=>{
					console.log(res)
					if(res.code == 200){
						this.accontData = res.data;
					}
				})
			},
			handleSuccess(res){
				if (res.code == 200) {
					this.form.rechargeScree = res.data.hash;
				}
			},
		},
		components: {
			OperaTip,
			SbUpload,
			ShowModalTip,
		},
		watch: {

			// data(newValue, oldValue) {
				
			// }
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {
			this.getApayList()
		},
		beforeUpdate() {
		
		},
		updated() {
		
		},
		beforeDestroy() {
		
		},
		destroyed() {
		
		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},
		
	}
</script>

<style scoped lang="less">
	.warning{
		margin-bottom: 20px;
	}
	.red{
		color: red;
	}
	.rechargeBox{
		display: flex;
		justify-content: center;
		align-items: center;
		.left{
			margin-right: 20px;
			width: 400px;
		}
		.right{
			text-align: center;

		}
	}
	.box-card{
		width: 980px;
	}
</style>
